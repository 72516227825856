import {Component, OnInit} from '@angular/core';
import { Router, RouterOutlet } from "@angular/router";
import { ToastsContainer } from './shared/toast/toast-container';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, ToastsContainer]
})
export class AppComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      window.scrollTo(0, 0)
    });
  }

}
