<div class="wrapper">
  <nav id="sidebar" [class.active]="isSidebarActive">

    <div class="row sidebar-header">
      <div class="col-md-8">
        <h4 class="text-white">Business Hub</h4>
      </div>
      <div class="col-md-4 text-end">
        <button class="btn btn-primary btn-lg" (click)="toggleSidebar()">
          <i *ngIf="!isSidebarActive" class="fas fa-arrow-left"></i>
          <i *ngIf="isSidebarActive" class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
    <hr *ngIf="!isSidebarActive">

    <ul class="list-unstyled components">
      <li>
        <a routerLink="/business/details">Business</a>
      </li>
      <li>
        <a routerLink="/business/events">Your Events</a>
      </li>
      <li>
        <a routerLink="/business/dashboard">Dashboard</a>
      </li>
      <hr *ngIf="!isSidebarActive">
    </ul>

  </nav>

  <div class="content" id="content">
    <router-outlet></router-outlet>
  </div>
</div>
