import {Component, TemplateRef} from '@angular/core';
import {ToastService} from './toast-service';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-toasts',
    template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)">
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>
      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
    host: { 'class': 'toast-container position-fixed bottom-0 end-0 p-3', 'style': 'z-index: 1200' },
    standalone: true,
    imports: [NgFor, NgbToast, NgIf, NgTemplateOutlet]
})
export class ToastsContainer {
  constructor(public toastService: ToastService) {
  }

  isTemplate(toast: { textOrTpl: any; }) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
