import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../models/user-model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {TokenService} from "./token.service";
import {jwtDecode} from "jwt-decode";
import {SignInDetails} from "../models/sign-in-model";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {SignUpDetails} from "../models/sign-up-model";
import {ActivationDetails} from "../models/activation-details";
import {SignUpBusinessDetails} from "../models/sign-up-business-model";
import {PasswordResetDetails} from "../models/password-reset-details";

interface DecodedToken {
  exp?: number;

  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_ROOT = environment.API_ROOT + "/api/v1/auth"

  private isBusinessAdminSubject: BehaviorSubject<boolean>;
  public isBusinessAdmin: Observable<boolean>;

  private isAuthenticatedSubject: BehaviorSubject<boolean>;
  public isAuthenticated: Observable<boolean>;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    private router: Router) {
    this.isBusinessAdminSubject = new BehaviorSubject<boolean>(false)
    this.isBusinessAdmin = this.isBusinessAdminSubject.asObservable();

    this.isAuthenticatedSubject = new BehaviorSubject<boolean>(false)
    this.isAuthenticated = this.isAuthenticatedSubject.asObservable();

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.tokenService.getCurrentUser()!));
    this.currentUser = this.currentUserSubject.asObservable();
    this.checkAuthentication();
  }

  getBusinessAdminStatus(): Observable<boolean> {
    return this.isBusinessAdminSubject.asObservable();
  }

  isBusinessAdminUser(): boolean {
    return this.isBusinessAdminSubject.value;
  }

  getAuthStatus(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  getUser(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  isLoggedIn(): boolean {
    return this.isAuthenticatedSubject.value;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setCurrentUserSubject(user: User) {
    this.currentUserSubject.next(user)
  }

  setCurrentUserItem(user: User) {
    const current = this.tokenService.setUserItem(user)
    this.setCurrentUserSubject(current)
    this.checkAuthentication()
  }

  private checkAuthentication(): void {
    try {
      const user = this.currentUserValue
      if (user && this.isTokenValid(user.accessToken)) {
        this.isAuthenticatedSubject.next(true);
        this.setBusinessAdminSubject(user.role)
      } else {
        this.refreshAccessToken(user.refreshToken)
      }
    } catch (error) {
      console.log("Error checking authentication")
      this.logout()
    }
  }

  private setBusinessAdminSubject(role: String): void {
    if (role === "BUSINESS_ADMIN") {
      this.isBusinessAdminSubject.next(true);
    } else {
      this.isBusinessAdminSubject.next(false);
    }
  }

  private isTokenValid(token: string): boolean {
    const decoded = jwtDecode(token) as DecodedToken;
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp! > currentTime;
  }

  private refreshAccessToken(refreshToken: string): void {
    this.refreshToken(refreshToken).subscribe({
      next: data => {
        const user = this.currentUserValue;
        user.accessToken = data.accessToken;
        this.setCurrentUserItem(user);
      },
      error: err => {
        this.logout();
      }
    })
  }

  login(details: SignInDetails): Observable<any> {
    let params = new HttpParams()
      .set('email', details.email)
      .set('password', details.password);
    return this.http.get(this.API_ROOT + "/login", {params});
  }

  refreshToken(refreshToken: string): Observable<any> {
    let params = new HttpParams()
      .set('token', refreshToken);
    return this.http.get(this.API_ROOT + "/refreshToken", {params});
  }

  signUpUser(details: SignUpDetails): Observable<any> {
    return this.http.post(this.API_ROOT + "/signUpUser", details);
  }

  signUpBusiness(details: SignUpBusinessDetails): Observable<any> {
    return this.http.post(this.API_ROOT + "/signUpBusiness", details);
  }

  confirmUser(details: ActivationDetails): Observable<any> {
    let params = new HttpParams()
      .set('email', details.email)
      .set('code', details.code);
    return this.http.get(this.API_ROOT + "/confirmUser", {params});
  }

  resendConfirmationCode(email: string): Observable<any> {
    let params = new HttpParams()
      .set('email', email)
    return this.http.get(this.API_ROOT + "/resendConfirmationCode", {params});
  }

  forgotPassword(email: string): Observable<any> {
    let params = new HttpParams()
      .set('email', email)
    return this.http.get(this.API_ROOT + "/forgotPassword", {params});
  }

  confirmForgotPassword(details: PasswordResetDetails) {
    let params = new HttpParams()
      .set('email', details.email)
      .set('code', details.code)
      .set('password', details.password);
    return this.http.get(this.API_ROOT + "/confirmForgotPassword", {params});

  }

  logout() {
    this.tokenService.removeAll()
    this.isAuthenticatedSubject.next(false);
    this.isBusinessAdminSubject.next(false);
    this.router.navigate(['/']).then(r => true)
  }

  removeToken() {
    this.tokenService.removeAll()
  }

}
