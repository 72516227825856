// src/app/app.routes.ts
import {Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {AuthBusinessGuard} from './auth/auth-business.guard';
import {BusinessLayoutComponent} from './layouts/business-layout/business-layout.component';
import {HomeLayoutComponent} from './layouts/home-layout/home-layout.component';
import {AccountLayoutComponent} from './layouts/account-layout/account-layout.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    data: { title: 'Home Views' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.routes').then((m) => m.homeRoutes),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.routes').then((m) => m.authRoutes),
      },
    ],
  },
  {
    path: 'account',
    component: AccountLayoutComponent,
    data: {title: 'Account Views'},
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./account/account.routes').then((m) => m.accountRoutes),
      },
    ],
  },
  {
    path: 'business',
    component: BusinessLayoutComponent,
    data: {title: 'Business Views'},
    canActivate: [AuthGuard, AuthBusinessGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./business/business.routes').then((m) => m.businessRoutes),
      },
    ],
  },
  {path: '**', redirectTo: ''},
];
