import { Component } from '@angular/core';
import { FooterComponent } from '../../shared/footer/footer.component';
import { SidebarComponent } from '../../shared/sidebar/sidebar.component';
import { NavbarComponent } from '../../shared/navbar/navbar.component';

@Component({
    selector: 'app-business-layout',
    templateUrl: './business-layout.component.html',
    styleUrls: ['./business-layout.component.scss'],
    standalone: true,
    imports: [NavbarComponent, SidebarComponent, FooterComponent]
})
export class BusinessLayoutComponent {

}
