import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../auth/auth.service";
import {User} from "../../models/user-model";
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf]
})
export class NavbarComponent implements OnInit {

  logoSrc = "/assets/images/logo/logo.png"
  altText = "NamEvents Logo"

  isBusinessAdmin: boolean = false;
  isLoggedIn: boolean = false;

  user: User;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.getBusinessAdminStatus().subscribe(status => {
      this.isBusinessAdmin = status;
    });
    this.authService.getAuthStatus().subscribe(status => {
      this.isLoggedIn = status;
    });
    this.authService.getUser().subscribe(user => {
      this.user = user;
    });
  }

  logout() {
    this.authService.logout();
  }

}
