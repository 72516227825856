import {importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';
import {AppComponent} from './app/app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {FullCalendarModule} from '@fullcalendar/angular';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {appRoutes} from './app/app.routes';
import {authInterceptorProviders} from "./app/interceptor.service";


bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(appRoutes),
    importProvidersFrom(
      BrowserModule,
      NgxGoogleAnalyticsModule.forRoot('G-E811C5HZHE'),
      NgxGoogleAnalyticsRouterModule,
      FullCalendarModule,
      NgbModule),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    authInterceptorProviders,
  ]
})
  .catch(err => console.error(err));
