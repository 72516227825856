<header class="shadow-lg fixed-top">

  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container">
      <a class="navbar-brand" routerLink="/">
        <img [src]=logoSrc class="logo" [alt]=altText height="70px">
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvasLg"
              aria-controls="navbarOffcanvasLg">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvasLg"
           aria-labelledby="navbarOffcanvasLgLabel">

        <div class="offcanvas-header">
          <a class="navbar-brand">
            <img [src]=logoSrc class="logo" [alt]=altText height="70px">
          </a>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body justify-content-end">
          <ul class="navbar-nav">

            <li class="nav-item" style="font-size: large">
              <a class="nav-link active" aria-current="page" routerLink="/" data-bs-dismiss="offcanvas">Home</a>
            </li>
            <li class="nav-item" style="font-size: large">
              <a class="nav-link active" aria-current="page" routerLink="/events"
                 data-bs-dismiss="offcanvas">Events</a>
            </li>
            <li class="nav-item non-mobile-only" style="font-size: large">
              <a class="nav-link active" aria-current="page" routerLink="/calendar"
                 data-bs-dismiss="offcanvas">Calendar</a>
            </li>
            <li class="nav-item" style="font-size: large">
              <a class="nav-link active" aria-current="page" routerLink="/about" data-bs-dismiss="offcanvas">About
                Us</a>
            </li>
            <li class="nav-item" style="font-size: large" *ngIf="!isBusinessAdmin">
              <a class="nav-link active" aria-current="page" routerLink="/auth/sign-up-business"
                 data-bs-dismiss="offcanvas">Become a Partner</a>
            </li>

            <div class="ps-md-4">
              <div *ngIf="!isLoggedIn">
                <button class="btn btn-outline-primary me-2" type="button" routerLink="/auth/sign-in"
                        data-bs-dismiss="offcanvas">Log In
                </button>
                <button class="btn btn-primary" type="button" routerLink="/auth/sign-up" data-bs-dismiss="offcanvas">
                  Sign Up
                </button>
              </div>

              <div *ngIf="isLoggedIn">
                <div class="dropdown">
                  <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ms-2 me-2 fas fa-user"></i> {{ user.name }}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a class="dropdown-item" routerLink="/account/profile" data-bs-dismiss="offcanvas">
                        <i class="fa-solid fa-user"></i> Account
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" routerLink="/account/registrations" data-bs-dismiss="offcanvas">
                        <i class="fa-solid fa-ticket"></i> My Tickets
                      </a>
                    </li>
                    <li>
                      <hr class="dropdown-divider">
                    </li>
                    <div *ngIf="isBusinessAdmin">
                      <li>
                        <a class="dropdown-item" routerLink="/business/details" data-bs-dismiss="offcanvas">
                          <i class="fa-solid fa-briefcase"></i> Business Hub
                        </a>
                      </li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                    </div>
                    <li>
                      <a class="dropdown-item" (click)="logout()" data-bs-dismiss="offcanvas">
                        <i class="fa-solid fa-right-from-bracket"></i> Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </ul>
        </div>
      </div>
    </div>
  </nav>

</header>
